// import pinia from '@/stores/store.js';
import Router from 'vue-router';
// import { useLoginStore } from '@/stores/loginStore';

import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'
// Dashboard pages
// import Overview from 'src/pages/Dashboard/Dashboard/Overview.vue'
// import Stats from 'src/pages/Dashboard/Dashboard/Stats.vue'

// Pages
// import User from 'src/pages/Dashboard/Pages/UserProfile.vue'
// import TimeLine from 'src/pages/Dashboard/Pages/TimeLinePage.vue'
// import Login from 'src/pages/Dashboard/Pages/Login.vue'
// import Register from 'src/pages/Dashboard/Pages/Register.vue'
// import Lock from 'src/pages/Dashboard/Pages/Lock.vue'
// import Commands from 'src/pages/Dashboard/Pages/Admin/Commands.vue'
// import OperatingSystems from 'src/pages/Dashboard/Pages/Monitoring/OperatingSystems.vue'
// import Queue from 'src/pages/Dashboard/Pages/Admin/Queue.vue'
// import ClientRunningCommands from 'src/pages/Dashboard/Pages/Admin/ClientRunningCommands.vue'
// import UserAuthgroupsAdmin from 'src/pages/Dashboard/Pages/Admin/Users/UserAuthgroupsAdmin.vue'
// import UserAdmin from 'src/pages/Dashboard/Pages/Admin/Users/UserAdmin.vue'
// import AuthGroupsScopesAdmin from 'src/pages/Dashboard/Pages/Admin/Users/AuthGroupsScopesAdmin.vue'
// import CommandMap from 'src/pages/Dashboard/Pages/Admin/CommandMap.vue'
// import Assignments from 'src/pages/Dashboard/Pages/Admin/Monitoring/Assignments.vue'
// import AIInterface from 'src/pages/Dashboard/Pages/AI/AIInterface.vue'
// import AuditLogs from 'src/pages/Dashboard/Pages/Reports/AuditLogs.vue'
// import Collectors from 'src/pages/Dashboard/Pages/Admin/CollectorAssignments.vue'
import CoachChat from 'src/pages/Dashboard/Pages/CoachChat.vue'
// import SNMPTrapTranslation from 'src/pages/Dashboard/Pages/Configuration/SNMPTrapTranslation.vue'

// All Monitoring plugins
// import Plugins from 'src/pages/Dashboard/Pages/Monitoring/Plugins.vue'
// import Checks from 'src/pages/Dashboard/Pages/Admin/Monitoring/Checks.vue'
// import AssignChecksToHosts from 'src/pages/Dashboard/Pages/Admin/Monitoring/AssignChecksToHosts.vue'
// import MainView from 'src/pages/Dashboard/Pages/Monitoring/MainView.vue'
// import Hosts from 'src/pages/Dashboard/Pages/Admin/Monitoring/Hosts.vue'
// import Alerts from 'src/pages/Dashboard/Pages/Monitoring/Alerts.vue'
// import Profiles from 'src/pages/Dashboard/Pages/Configuration/Profiles.vue'
// import BackupRestore from 'src/pages/Dashboard/Pages/Tools/BackupRestore.vue'
// import Fixit from 'src/pages/Dashboard/Pages/Tools/Fixit.vue'
// import Support from 'src/pages/Dashboard/Pages/Support/Support.vue'


// Components pages
// import Buttons from 'src/pages/Dashboard/Components/Buttons.vue'
// import GridSystem from 'src/pages/Dashboard/Components/GridSystem.vue'
// import Panels from 'src/pages/Dashboard/Components/Panels.vue'
// const SweetAlert = () => import('src/pages/Dashboard/Components/SweetAlert.vue')
// import Notifications from 'src/pages/Dashboard/Components/Notifications.vue'
// import Icons from 'src/pages/Dashboard/Components/Icons.vue'
// import Typography from 'src/pages/Dashboard/Components/Typography.vue'
// import ClientCompletedCommands from "../pages/Dashboard/Pages/Admin/ClientCompletedCommands.vue";
// import AvailabilityReport from "../pages/Dashboard/Pages/Reports/AvailabilityReport.vue";
// import HostGroups from "../pages/Dashboard/Pages/Admin/Monitoring/HostGroups.vue";
// import UserGroupAssignments from "../pages/Dashboard/Pages/Admin/Monitoring/UserGroupAssignments.vue";
// import UserGroups from "../pages/Dashboard/Pages/Admin/Monitoring/UserGroups.vue";
// import Scheduling from "../pages/Dashboard/Pages/Admin/Scheduling.vue";
// import SNMP from "../pages/Dashboard/Pages/Admin/Monitoring/SNMP.vue";
// import Email from "../pages/Dashboard/Pages/Admin/Email.vue";
// import License from "../pages/Dashboard/Pages/Admin/License.vue";

// Forms pages
// const RegularForms = () => import('src/pages/Dashboard/Forms/RegularForms.vue')
// const ExtendedForms = () => import('src/pages/Dashboard/Forms/ExtendedForms.vue')
// const ValidationForms = () => import('src/pages/Dashboard/Forms/ValidationForms.vue')
// const Wizard = () => import('src/pages/Dashboard/Forms/Wizard.vue')

// TableList pages
// const RegularTables = () => import('src/pages/Dashboard/Tables/RegularTables.vue')
// const ExtendedTables = () => import('src/pages/Dashboard/Tables/ExtendedTables.vue')
// const PaginatedTables = () => import('src/pages/Dashboard/Tables/PaginatedTables.vue')
// Maps pages
// const GoogleMaps = () => import('src/pages/Dashboard/Maps/GoogleMaps.vue')
// const FullScreenMap = () => import('src/pages/Dashboard/Maps/FullScreenMap.vue')
// const VectorMaps = () => import('src/pages/Dashboard/Maps/VectorMapsPage.vue')

// Calendar
// const Calendar = () => import('src/pages/Dashboard/Calendar/CalendarRoute.vue')
// Charts
// const Charts = () => import('src/pages/Dashboard/Charts.vue')

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/chat',
    children: [
      {
        path: 'chat',
        name: 'Chat',
        component: CoachChat
      },
    ]
  },
  {path: '*', component: NotFound}
]

export const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});
/*
router.beforeEach((to, from, next) => {
  const publicPages = ['/'];
  const authRequired = !publicPages.includes(to.path);
  const store = useLoginStore();
  const loggedIn = store.loggedIn;

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});
*/

<template>
<div>
  <ChatComponent />
</div>
</template>

<script>
import ChatComponent from "../Components/CoachChatComponent.vue";
export default {
  name: "CoachChat",
  components: {
    ChatComponent,
  },
}
</script>

<style scoped>

</style>

import { defineStore } from 'pinia';
export const useLoginStore = defineStore('loginStore', {
  state: () => ({
    username: '',
    name: '',
    user: null,
    userId: 0,
    appRights: [],
    hostRights: [],
    commandRights: [],
    userClients: [],
    loggedIn: false,
    sideMenu: false,
  }),
  getters: {
    getUsername(state) {
      return state.username;
    },
    getName(state) {
      return state.name;
    },
    getUserScopes(state) {
      return state.userScopes;
    },
    getUserGroups(state) {
      return state.userGroups;
    },
    getLoggedIn(state) {
      return state.loggedIn;
    }
  },
  actions: {
    setLoggedIn(loggedIn) {
      this.loggedIn = loggedIn;
    },
    setUser(user) {
      this.user = user;
    },
    setName(name) {
      this.name = name;
    },
    setClients(clients) {
      this.clients = clients;
    },
    setUsername(name) {
      this.username = name;
    },
    setAppRights(appRights) {
      this.appRights = appRights;
    },
    setCommandRights(commandRights) {
      this.commandRights = commandRights;
    },
    setHostRights(hostRights) {
      this.hostRights = hostRights;
    }
  }
})

